.Join {
    color: white;
    padding: 3rem;
}

.Join h1 {
    padding-bottom: 1.5rem;
}

.Join hr {
    border: 2px solid white;
    border-radius: 5%;
    width: 12.5%;
}

.Join-button {
    position: relative;
}

.Join-button-mobile {
    display: none;
}

.Join-button a {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0.75rem;
    width: 50%;
    font-size: larger;
}

@media only screen and (max-width: 1000px) {
    .Join-button {
        display: none;
    }

    .Join-button-mobile {
        display: contents;
        width: 100%;
    }
}