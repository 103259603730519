.FAQ h1 {
    color: white;
    text-align: center;
    margin-bottom: 3rem;
}

.FAQ-item {
    background-color: rgba(50,75,100,1);
    margin-bottom: 3rem;
}

.FAQ-body {
    padding: 2rem;
    color: white;
}

.FAQ-btn {
    font-size: large;
    transition: 2s;
    padding: 4%;
    color: rgb(190, 190, 190);
}

.FAQ {
    width: 35%;
}

.FAQ-btn:hover {
    color: white;
}

@media only screen and (max-width: 1000px) {
    .FAQ-item {
        width: 100%;
        padding: 5%;
    }
    .FAQ {
        width: 100%;
        text-align: center !important;
    }
}