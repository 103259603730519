.Header-logo {
    height: 72px;
}

header {
    background-image: url(https://cdn.tga.fyi/tlb/tlb-header.jpg);
    background-position: 50% 75%;
    background-repeat: no-repeat;
    background-size: 100%;
}

.Header-nav {
    padding-top: 1em;
    padding-bottom: 1em;
    margin-bottom: 10em;
    background-color: rgba(0, 0, 0, 0.25);
}

.Header-button {
    margin-right: 2em;
    width: 7rem;
    text-align: center;
    transition: 2s;
    color: white;
}
.Header-button:last-child {
    width: 7rem;
    margin-right: 0;
    text-align: center;
    transition: 2s;
}

.Header-button:hover {
    color: #ec9706;
}

.Header-toggler {
    border: var(--bs-border-width) solid white;
}

.Header-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");}

@media only screen and (max-width: 1000px) {
    .Header-button {
        margin-top: 1em;
        padding: 1em 1em;
        width: 10rem;
        text-align: left !important;
    }    
    header {
        background-image: url(https://cdn.tga.fyi/tlb/tlb-header.jpg);
        background-position: 50% 75%;
        background-repeat: no-repeat;
        background-size: auto;
        text-align: center;
    }
    .Header-join {
        text-align: center;
        padding-top: 2rem;
        padding-bottom: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
        border: dashed white 1px;
        font-size: 12pt !important;
        font-weight: bolder;
        background-color: rgba(236, 151, 6, 0.15);
        font-family: 'Montserrat';
    }

    .Header-mission-statement h1 {
        font-size: 48pt !important;
    }

    #basic-navbar-nav {
        text-align: center;
    }
}

.Header-join {
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 1rem;
    border: dashed white 1px;
    font-size: 28pt;
    font-weight: bolder;
    background-color: rgba(236, 151, 6, 0.15);
    font-family: 'Montserrat';
}

@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@200&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');

.Header-mission-statement {
    color: white;
    font-size: xx-large;
    font-family: 'Overpass';
}

.Header-mission-statement h1 {
    font-family: "Jost", sans-serif;
    text-align: center;
    font-size: 72pt;
    font-weight: bold;
    font-kerning: none;
    text-shadow: 3px 3px rgba(0, 0, 0, 0.5);
}

.Header-mission-statement p {
    text-align: center;
    font-weight: 500;
    font-size: 32pt;
    font-kerning: none;
    text-shadow: 3px 3px rgba(0, 0, 0, 0.5);
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.Header-fade {
    height: 200px;
    background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(22,25,35,1));
}