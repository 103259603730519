.Footer {
    margin-top: 2em;
    padding-bottom: 2em;
    color: white;
    text-decoration: none;

}

.Footer-credit {
    text-decoration: none; 
    color: #0091ff;
}

.Footer-Links {
    text-align: right;
}

.Footer-Links ul {
    list-style: none;
}

.Footer-Link {
    text-decoration: none;
}

.Footer-icon {
    display: inline;
    padding-left: 1em;
}

.Footer-Logos {
    height: fit-content;
    display: inline;
    text-align: center;
}