.Games {
    padding-top: 1rem; 
    font-family: 'Montserrat', sans-serif;
    background-color: rgba(22,25,35,1);
    color: white;
}

.Games-images-1 {
    background-image: url('https://cdn.tga.fyi/tlb/tlb1.jpg'); 
    background-position: center; 
    background-repeat: no-repeat;
    background-size: 200%;
    height: 25rem;
}

.Games-images-2 {
    background-image: url('https://cdn.tga.fyi/tlb/tlb2.jpg'); 
    background-position: 20% 50%; 
    background-size: 220%;
    background-repeat: no-repeat;
    height: 25rem;
}

.Games-images-3 {
    background-image: url('https://cdn.tga.fyi/tlb/tlb3.jpg'); 
    background-position: 25% 50%; 
    background-size: 200%;
    background-repeat: no-repeat;
    height: 25rem;
}

.Games-para {
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.Games-three-images {
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 100%;
}


@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

.Games-Info-1 {
    border-radius: 0px 25px 0px 0px;
}

.Games-Info-2 {
    border-radius: 0px 0px 0px 25px;
}

.Games-who {
    border-radius: 25px 25px 0px 0px;
    background-image: url('https://cdn.tga.fyi/tlb/who.jpg'); 
    background-position: center; 
    background-size: 100%;
    background-repeat: no-repeat;
    color: white;
}
.Games-how {
    border-radius: 0px 0px 25px 25px;
    background-image: url('https://cdn.tga.fyi/tlb/how.jpg'); 
    background-position: center; 
    background-position: 0%; 
    background-size: 100%;
    background-repeat: no-repeat;
    color: white;
}

.Games-Info {
    padding: 5rem;
    background-color: rgba(0, 0, 0, 0.5);
}

.Games-Info hr {
    border-radius: 1%;
    border: 0.15rem solid white;
}

@media only screen and (max-width: 1000px) {
    .Games {
        text-align: right;
    }
    .Games-Info {
        background-color: rgba(0, 0, 0, 0.25);
        text-align: center;
        width:100%;
        padding: 1rem;
    }

    .Games-image {
        width: 0 !important;
        visibility: hidden !important;
        display: none !important;
    }

    .Games-how {
        border-radius: 0px 0px 25px 25px;
        background-size: 200%;
        text-align: center !important;
        background-size: cover !important;
    }

    .Games-Align-Right {
        text-align: center !important;
    }

    .Games-who {
        border-radius: 25px 25px 0px 0px;
        background-size: cover !important;
    }

    .Games-Info-1 {
        border-radius: 25px 25px 0px 0px;
    }

    .Games-Info-2 {
        border-radius: 0px 0px 25px 25px;
    }

    .Games-images-1 {
        background-image: none;
        height: 0;
    }
    .Games-images-2 {
        background-image: none;
        height: 0;
        border: 0.25rem solid rgba(255, 255, 255, 0.25);
        border-radius: 5px;
    }
    .Games-images-3 {
        background-image: none;
        height: 0;

    }
    .Games-image img {
        height: 196px;
    }

    .Games-Order-First {
        order: 13;
    }
    .Games-Order-Last {
        order: -1;
    }
}

.Games-image {
    display: flex;
    justify-content: center;
}

.Games-Align-Right {
    text-align: right;
}